import ImageHandler from '../../../../shared/components/ImageHandler'
import Fancybox from '../../../../shared/components/Fancybox'
import { FaHeart, FaRegHeart } from 'react-icons/fa'
import { BsCart, BsCartCheckFill } from 'react-icons/bs'
import { CiZoomIn } from "react-icons/ci";
import { IProduct } from '../../../../shared/interfaces/IProduct'
import { formatPrice } from '../../../../shared/functions/formatPrice'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import wishlistSlice from '../../../../store/slices/wishlist.slice'
import cartSlice from '../../../../store/slices/cart.slice'
import { countNewPrice } from '../../../../shared/functions/countNewPrice';
import { Badge } from 'react-bootstrap';
import { scrollTo } from '../../../../shared/functions/scrollTo';

interface IProps {
    product: IProduct;
}

export default function ProductCard({ product }: IProps) {
    const dispatch = useDispatch();
    const wishlist = useSelector((state: RootState) => state.wishlist);
    const cart = useSelector((state: RootState) => state.cart);
    const navigate = useNavigate();

    const isWishlist = wishlist.some((item) => item?.id === product?.id);
    const isInCart = cart.some((item) => item?.id === product?.id);

    const newPrice = countNewPrice(product?.price as number, product?.discount as number);

    const handleWislistClick = (productId: number, isInList = false) => {
        if (isInList) {
            const updatedList = wishlist.filter((item) => item.id !== productId);
            dispatch(wishlistSlice.actions.setWishlist(updatedList));
            localStorage.setItem("wishlist", JSON.stringify(updatedList));
        } else {
            const updatedList = wishlist.concat({
                ...product,
                cartQuantity: 1
            } as IProduct);

            dispatch(wishlistSlice.actions.setWishlist(updatedList));
            localStorage.setItem("wishlist", JSON.stringify(updatedList));
        }
    }

    const handleAddToCart = (productId: number, isInList = false, isInWishlist = false) => {
        if (isInList) {
            const updatedCart = cart.filter((item) => item?.id !== productId);
            dispatch(cartSlice.actions.setCart(updatedCart));
            localStorage.setItem("cart", JSON.stringify(updatedCart));
        } else {
            const updatedCart = cart.concat({
                ...product,
                cartQuantity: 1,
            } as IProduct);

            const updatedWishlist = wishlist.filter((item: IProduct) => item?.id !== product?.id);
            if (isInWishlist) {
                dispatch(wishlistSlice.actions.setWishlist(updatedWishlist));
                localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
            }

            dispatch(cartSlice.actions.setCart(updatedCart));
            localStorage.setItem("cart", JSON.stringify(updatedCart));
        }
    }

    return (
        <div className='card border-0 product-card mb-5'>
            <div className="position-relative">
                <Fancybox
                    options={{
                        Carousel: {
                            infinite: false,
                        },
                    }}
                >
                    {!!product?.discount && <Badge className="promo-badge bg-danger"> {product?.discount}%</Badge>}
                    <ImageHandler src={product?.productPhotos?.[0]?.path} className="w-100 object-fit-cover h-300px cursor-pointer"
                        onClick={() => {
                            navigate(`/product/${product?.id}`);
                            scrollTo();
                        }}
                    />

                    <div className='product-side-buttons d-none d-lg-flex'>
                        {isWishlist ? (
                            <button
                                className="product-side-btn wishlist"
                                onClick={(e) => {
                                    // e.stopPropagation();
                                    handleWislistClick(Number(product?.id), true)
                                }
                                }
                            >
                                <FaHeart className="wh-20px cursor-pointer text-danger" />
                            </button>)
                            :
                            (<button
                                className="product-side-btn wishlist"
                                onClick={(e) => {
                                    // e.stopPropagation();
                                    handleWislistClick(Number(product?.id))
                                }}
                            >
                                <FaRegHeart className="wh-20px cursor-pointer text-muted" />
                            </button>)
                        }

                        {isInCart ? (
                            <button
                                className="product-side-btn cart"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleAddToCart(Number(product?.id), true, isWishlist)
                                }}>
                                <BsCartCheckFill className="wh-20px text-primary cursor-pointer" />
                            </button>
                        ) :
                            (<button
                                className="product-side-btn cart"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleAddToCart(Number(product?.id), isInCart, isWishlist)
                                }}>
                                <BsCart className="wh-20px cursor-pointer" />
                            </button>)}

                        <a data-fancybox="gallery" href={product?.productPhotos?.[0]?.path}>
                            <button
                                className="product-side-btn zoomin">
                                <CiZoomIn className=" wh-20px cursor-pointer" />
                            </button>
                        </a>
                    </div>


                    {/* MOBILE */}
                    <div className='position-absolute bottom-0 w-100 mb-2'>
                        <div className='d-flex d-lg-none align-items-center justify-content-evenly'>
                            {isWishlist ? (
                                <button
                                    className="product-btn"
                                    onClick={(e) => {
                                        handleWislistClick(Number(product?.id), true)
                                    }
                                    }
                                >
                                    <FaHeart className="wh-20px cursor-pointer text-danger" />
                                </button>)
                                :
                                (<button
                                    className="product-btn"
                                    onClick={(e) => {
                                        handleWislistClick(Number(product?.id))
                                    }}
                                >
                                    <FaRegHeart className="wh-20px cursor-pointer text-muted" />
                                </button>)
                            }

                            {isInCart ? (
                                <button
                                    className="product-btn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleAddToCart(Number(product?.id), true, isWishlist)
                                    }}>
                                    <BsCartCheckFill className="wh-20px text-primary cursor-pointer" />
                                </button>
                            ) :
                                (<button
                                    className="product-btn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleAddToCart(Number(product?.id), isInCart, isWishlist)
                                    }}>
                                    <BsCart className="wh-20px cursor-pointer" />
                                </button>)}

                            <a data-fancybox="gallery" href={product?.productPhotos?.[0]?.path}>
                                <button
                                    className="product-btn">
                                    <CiZoomIn className=" wh-20px cursor-pointer" />
                                </button>
                            </a>
                        </div>
                    </div>
                </Fancybox>
            </div>



            <div className="card-body d-flex flex-column px-0">
                <h6 className="fw-bold mb-0"> {product?.name}</h6>
                <small className='my-1'> {product?.sku} </small>
                {/* <small className='my-1'> {product?.color} </small>
                <small className='my-1'> {product?.category?.name}</small>
                <small className='my-1'> {product?.brandProduct?.name}</small> */}

                {!!product?.discount ? (
                    <div className="d-flex align-items-center">
                        <span className="text-primary text-decoration-line-through"> {formatPrice(product?.price as number)}</span>
                        <span className="text-danger fw-bold ms-3"> {formatPrice(+newPrice)}</span>
                    </div>
                ) : (<span className="text-primary fw-bold"> {formatPrice(product?.price as number)}</span>)}
            </div>
        </div>
    )
}
