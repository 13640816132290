import { TFunction } from 'i18next';
import React from 'react';
import { EOrderStatus } from '../enums/EOrderStatus';
import { formatDate } from './formatDate';
import { formatPrice } from './formatPrice';
import BadgeStatus from '../components/BadgeStatus';
import { clearHTML } from './clearHTML';
import { getCtaColor } from './getCtaColor';
import { countNewPrice } from './countNewPrice';

export const renderCell = (
    key: string,
    value: string | number,
    t?: TFunction<'translation', undefined>,
    onButtonClick?: () => void,
    row?: any
) => {
    if (['createdAt', 'updatedAt'].includes(key)) {
        return formatDate(value as string)
    }
    if (key === 'status') {
        return <BadgeStatus status={value as EOrderStatus} />
    }
    if (['price'].includes(key)) {
        return formatPrice(Number(value))
    }
    if (key === 'total') {
        const userDiscount = Number(row?.items?.[0]?.userDiscount ?? 0);
        const totalPrice = (row?.items)?.reduce((prev: any, curr: any) => prev +
            (
                +countNewPrice(curr?.originalPrice as number, curr?.originalDiscount as number) * curr?.quantity
            ), 0);

        return formatPrice(countNewPrice(totalPrice, userDiscount));
    }
    if (['isFeatured'].includes(key) && t) {
        return value ? t('general.yes') : t('general.no');
    }
    if (['view', 'delete'].includes(key)) {
        return (
            <button type="button" className={`${key === "view" && 'bg-secondary text-white'}  
            w-100 text-uppercase btn btn-${getCtaColor(key)} me-1 btn-sm`}
                onClick={onButtonClick}
            >
                {t ? t(`buttons.${key}`) : key}
            </button>
        )
    }
    return clearHTML(String(value || '-'));
}