import { FaRegCheckCircle } from "react-icons/fa";
import OrderDetails from './OrderDetails';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IOrder } from "../../../../shared/interfaces/IOrder";
import ConfirmedOrderDetails from "./ConfirmedOrderDetails";

interface IProps {
    placedOrder: IOrder
}

export default function OrderConfirmation({ placedOrder }: IProps) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className="d-flex flex-column justify-content-center align-items-center mt-5">
            <div className="d-flex flex-column align-items-center">
                <FaRegCheckCircle className={'text-primary checkout-success'} />
                <h2 className={'my-2'}> {t("cart.completedOrder")} </h2>
                <small className="text-muted"> {t("cart.orderReceivedMess")} </small>
            </div>

            <div className='p-md-5 my-3'>
                <ConfirmedOrderDetails placedOrder={placedOrder} />
            </div>

            <OrderDetails cart={placedOrder} isConfirmation={true} />

            <Button variant="primary" className="text-uppercase p-2 mt-4 mb-5 rounded-0" onClick={() => navigate("/")}>
                {t("buttons.backToHomepage")} </Button>
        </div>
    )
}
