import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { useTranslation } from 'react-i18next';
import { IOrder } from '../../../shared/interfaces/IOrder';
import Login from '../../../authentification/login';
import CartTable from './components/CartTable';
import Checkout from './components/Checkout';
import OrderConfirmation from './components/OrderConfirmation';
import { checkoutSteps } from '../../../utils/data/checkoutSteps';
import EmptyList from '../../../shared/components/EmptyList';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { registerSchema } from '../../../authentification/register';
import { IRegisterInput } from '../../../shared/interfaces/IRegisterInput';

export default function Cart() {
    const cart = useSelector((state: RootState) => state.cart);
    const [currentStep, setCurrentStep] = useState(1);
    const reduxUser = useSelector((state: RootState) => state.auth.userData);
    const { register, getValues, formState: { errors } } = useForm<IRegisterInput>({
        resolver: yupResolver(registerSchema),
        defaultValues: {
            ...reduxUser
        }
    });

    const { t } = useTranslation();
    const [placedOrder, setPlacedOrder] = useState<IOrder | null>(null);

    const stepperComponents = {
        1: <CartTable cart={cart} setCurrentStep={setCurrentStep} />,
        2: !!getValues().id ? (<Checkout
            register={register}
            errors={errors}
            currentUser={getValues()}
            cart={cart}
            setCurrentStep={setCurrentStep}
            setPlacedOrder={setPlacedOrder} />)
            : (<Login />),
        3: <OrderConfirmation placedOrder={placedOrder as IOrder} />
    }

    const getStepperComponents = (stepId: number) => {
        return (stepperComponents[stepId as keyof typeof stepperComponents] || `No component for step ${stepId}`);
    }

    return (
        <>
            {cart?.length || currentStep === 3 ? (
                <>
                    <div className="d-none d-md-flex row border-bottom mt-5">
                        {checkoutSteps?.map((step) => {
                            return (
                                <div
                                    key={step?.id}
                                    onClick={() => {
                                        if (step?.id <= currentStep) {
                                            setCurrentStep(step?.id);
                                        }
                                    }}
                                    className={`col-md-4 ps-0 border-bottom 
                            border-${step?.id <= currentStep
                                            ? "primary cursor-pointer"
                                            : "muted opacity-50 cursor-not-allowed"
                                        }`}
                                >
                                    <h5 className="text-uppercase">
                                        0{step?.id} {t(step?.name)}
                                    </h5>
                                    <small className="text-muted ms-lg-4">
                                        {t(step?.description)}
                                    </small>
                                </div>
                            );
                        })}
                    </div>
                    <div className="my-3">{getStepperComponents(currentStep)}</div>
                </>
            ) : (
                <div className={"my-3"}>
                    <EmptyList />
                </div>
            )}
        </>
    )
}
