import { Col, Row } from 'react-bootstrap'
import { formatDate } from '../../../../shared/functions/formatDate'
import { useTranslation } from 'react-i18next';
import { IOrder } from '../../../../shared/interfaces/IOrder';
import { formatPrice } from '../../../../shared/functions/formatPrice';
import { getTotalPriceOrder } from '../../../../shared/functions/getTotalPriceOrder';
import { countNewPrice } from '../../../../shared/functions/countNewPrice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';

interface IProps {
    placedOrder: IOrder
}

export default function ConfirmedOrderDetails({ placedOrder }: IProps) {
    const { t } = useTranslation();
    const { userData } = useSelector((state: RootState) => state.auth);

    return (
        <>
            <Row className="d-none d-md-flex">
                <Col md={3}>
                    <span className="text-muted"> {t("cart.orderNumber")} </span>
                </Col>

                <Col md={3}>
                    <span className="text-muted"> {t("cart.date")} </span>
                </Col>

                <Col md={3}>
                    <span className="text-muted"> {t("cart.total")} </span>
                </Col>

                <Col md={3}>
                    <span className="text-muted"> {t("cart.paymentMethod")}</span>
                </Col>

                <Col md={3}>
                    <h5> #{placedOrder?.id} </h5>
                </Col>

                <Col md={3}>
                    <h5> {formatDate(placedOrder?.createdAt as string)}.</h5>
                </Col>

                <Col md={3}>
                    <h5>  {userData?.initialDiscount ? formatPrice(countNewPrice(getTotalPriceOrder(placedOrder as IOrder), userData?.initialDiscount))
                        : formatPrice(getTotalPriceOrder(placedOrder as IOrder))} </h5>
                </Col>

                <Col md={3}>
                    <h5> {t("cart.cashOnDelivery")}</h5>
                </Col>
            </Row>

            <div className="d-md-none my-3 px-3">
                <div className="row">
                    <div className="col-6">
                        <span className="text-muted"> {t("cart.orderNumber")} </span>
                        <h5> #{placedOrder?.id} </h5>
                    </div>

                    <div className="col-6">
                        <span className="text-muted"> {t("cart.date")} </span>
                        <h5> {formatDate(placedOrder?.createdAt as string)}.</h5>
                    </div>

                    <div className="col-6">
                        <span className="text-muted"> {t("cart.total")} </span>
                        <h5>  {userData?.initialDiscount ? formatPrice(countNewPrice(getTotalPriceOrder(placedOrder as IOrder), userData?.initialDiscount))
                            : formatPrice(getTotalPriceOrder(placedOrder as IOrder))} </h5>
                    </div>

                    <div className="col-6">
                        <span className="text-muted"> {t("cart.paymentMethod")}</span>
                        <h5> {t("cart.cashOnDelivery")}</h5>
                    </div>
                </div>
            </div>

        </>

    )
}
