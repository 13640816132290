import React from 'react'
import { IProduct } from '../../../../shared/interfaces/IProduct'
import { Col, Row } from 'react-bootstrap'
import { formatPrice } from '../../../../shared/functions/formatPrice'
import { useTranslation } from 'react-i18next'
import { IOrder } from '../../../../shared/interfaces/IOrder'
import { getTotalPrice } from '../../../../shared/functions/getTotalPrice'
import { countNewPrice } from '../../../../shared/functions/countNewPrice'
import { getTotalPriceOrder } from '../../../../shared/functions/getTotalPriceOrder'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'

interface IProps {
    cart: IProduct[] | IOrder
    isConfirmation?: boolean
}

export default function OrderDetails({ cart, isConfirmation = false }: IProps) {
    const { userData } = useSelector((state: RootState) => state.auth);

    const { t } = useTranslation();
    const products = (isConfirmation ?
        (cart as IOrder)?.items?.map(product => ({ ...product.product, cartQuantity: product?.quantity }))
        : cart) as IProduct[];

    return (
        <div className={`${!isConfirmation && 'ms-lg-5 mt-5'} border border-primary px-5 py-4 mx-2 mx-md-0`}>
            <Row className={'mb-4'}>
                <Col md={12} className={'px-0'}>
                    <h5 className={'text-uppercase'}> {t("cart.yourOrder")} </h5>
                </Col>
            </Row>
            <Row>
                <div className="col-6 px-0 border-bottom border-muted pb-3">
                    <h6 className={'text-uppercase'}> {t("cart.product")} </h6>
                </div>

                <div className="col-6 border-bottom border-muted pb-3">
                    <h6 className={'text-uppercase'}> {t("cart.subtotal")} </h6>
                </div>

                {products?.map(product => {
                    return (
                        <React.Fragment key={product?.id}>
                            <div className="col-6 d-flex flex-column border-bottom border-muted px-0">
                                <span className="text-muted my-2 small">
                                    {product?.name} x {product?.cartQuantity}
                                </span>
                            </div>
                            <div className="col-6 d-flex flex-column border-bottom border-muted">
                                {!!product?.discount ? (
                                    <span className="text-muted my-2 small"> {formatPrice(+countNewPrice(product?.price as number, product?.discount as number) * Number(product?.cartQuantity))} </span>
                                ) : (<span className="text-muted my-2 small"> {formatPrice((product?.price as number) * Number(product?.cartQuantity))}</span>)}

                            </div>
                        </React.Fragment>
                    )
                })}
            </Row>

            {!!userData?.initialDiscount && <Row className={'pt-3'}>
                <div className="col-6 px-0">
                    <h6 className={'text-uppercase'}> {t('general.discount')} </h6>
                </div>

                <div className="col-6 d-flex flex-column">
                    <span className={'text-danger fw-semibold'}>
                        10%
                    </span>
                </div>
            </Row>}

            <Row className={'pt-3'}>
                <div className="col-6 px-0">
                    <h6 className={'text-uppercase'}> {t("cart.total")} </h6>
                </div>

                <div className="col-6 d-flex flex-column">
                    <span className={'text-primary fw-semibold'}>
                        {userData?.initialDiscount ? formatPrice(countNewPrice(isConfirmation ? getTotalPriceOrder(cart as IOrder) : getTotalPrice(cart as IProduct[]), userData?.initialDiscount))
                            : formatPrice(isConfirmation ? getTotalPriceOrder(cart as IOrder) : getTotalPrice(cart as IProduct[]))}

                        {/* {formatPrice(isConfirmation ? getTotalPriceOrder(cart as IOrder) : getTotalPrice(cart as IProduct[]))} */}
                    </span>

                    {!isConfirmation && <small className={'text-muted'}> {t("cart.cashOnDelivery")} </small>}
                </div>
            </Row>
        </div>
    )
}
