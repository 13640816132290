import React, { SetStateAction } from 'react'
import { IUser } from '../../../interfaces/IUser'
import { useTranslation } from 'react-i18next'
import { Button, Form } from 'react-bootstrap';
import ImageHandler from '../../../components/ImageHandler';

interface IProps {
    currentUser: IUser | null,
    handleChange: React.ChangeEventHandler<HTMLInputElement>,
    file: File | null,
    setFile: React.Dispatch<SetStateAction<File | null>>,
    userData: IUser | undefined,
}

export default function PersonalForm({ currentUser, handleChange, file, setFile, userData }: IProps) {
    const { t } = useTranslation();

    return (
        <div className='row'>
            <h6 className='text-uppercase ps-0 mb-3'> {t("general.personalDataChange")} </h6>
            <div className="col-md-8 ps-0">
                <Form.Label className="mb-0 small"> {t("general.firstName")} </Form.Label>
                <Form.Control type="text"
                    name="firstName"
                    value={currentUser?.firstName}
                    placeholder={t("general.firstName")}
                    className="rounded-0 mb-3 p-3"
                    onChange={handleChange} />

                <Form.Label className="mb-0 small"> {t("general.lastName")} </Form.Label>
                <Form.Control type="text"
                    name="lastName"
                    value={currentUser?.lastName}
                    placeholder={t("general.lastName")}
                    className="rounded-0 mb-3 p-3"
                    onChange={handleChange} />

                <Form.Label className="mb-0 small"> {t("general.username")} </Form.Label>
                <Form.Control type="text"
                    name="profile"
                    value={currentUser?.profile}
                    placeholder={t("general.username")}
                    className="rounded-0 p-3 mb-3"
                    onChange={handleChange} />

                <Form.Label className="mb-0 small"> {t("general.email")} </Form.Label>
                <Form.Control type="email"
                    name="email"
                    value={currentUser?.email}
                    placeholder={t("general.email")}
                    className="rounded-0 p-3"
                    onChange={handleChange} />
            </div>

            <div className="col-md-4 border-dashed-gray mb-3 px-0 d-flex justify-content-center h-400px">
                <label>
                    {file ?
                        <ImageHandler
                            className="object-fit-contain w-100 cursor-pointer h-100"
                            src={URL.createObjectURL(file)}
                        /> :
                        <ImageHandler
                            errorPath='https://t4.ftcdn.net/jpg/05/42/36/11/360_F_542361185_VFRJWpR2FH5OiAEVveWO7oZnfSccZfD3.jpg'
                            className="object-fit-contain w-100 cursor-pointer h-400px"
                            src={userData?.profilePhoto?.path as string} />
                    }
                    <input type="file"
                        name="profilePhoto"
                        onChange={(e) => setFile(e?.target?.files?.[0] || null)}
                        accept='image/*'
                        style={{ display: "none" }} />
                </label>
            </div>

            <div className='d-flex justify-content-center justify-content-md-start px-0'>
                <Button className="w-save-btn rounded-0 " type="submit"> {t("buttons.save")}</Button>
            </div>
        </div>
    )
}
