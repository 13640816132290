import React, { useEffect, useState } from "react";
import { IProduct } from "../../../shared/interfaces/IProduct";
import { ProductService } from "../../admin/products/service";
import { usePagination } from "../../../shared/hooks/usePagination";
import { handleError } from "../../../shared/functions/handleError";
import { useTranslation } from "react-i18next";
import ProductCard from "./components/ProductCard";
import { ProgressBar, Spinner } from "react-bootstrap";
import { IPagination } from "../../../shared/interfaces/IPagination";
import { EOrderType } from "../../../shared/enums/EOrderType";
import ShopFilter from "./components/ShopFilter";

export default function Shop() {
  const [shopProducts, setShopProducts] = useState<IProduct[]>([]);
  const { pagination, setPagination, totalCount, setTotalCount, changeFilterHandler, setCount } = usePagination({
    perPage: 12,
    paginationKeys: {
      orderType: EOrderType.DESC,
      orderCriteria: "price",
    },
  })

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [colSize, setColSize] = useState<number>(4);

  const { t } = useTranslation();
  const percentage = Math.ceil((shopProducts?.length / totalCount) * 100);

  const fetchMoreData = () => {
    setPagination((prev: IPagination) => ({
      ...prev,
      perPage: prev.perPage + 12,
    }))
  }

  useEffect(() => {
    ProductService.getAllProducts(pagination).then(response => {
      setShopProducts(response?.data);
      setCount(Math.ceil(response.count / response.perPage));
      setTotalCount(response?.count)
    })
      .catch(err => handleError(err))
      .finally(() => setIsLoading(false));
  }, [pagination, setCount, setTotalCount, setShopProducts]);

  return <>

    <div className="row">
      <div className="col-12 px-0 mb-3 mt-md-3">
        <ShopFilter colSize={colSize} setColSize={setColSize} changeFilterHandler={changeFilterHandler} pagination={pagination} />
      </div>
    </div>
    {!isLoading ? (
      !!shopProducts?.length ? (
        <div className="row">
          {shopProducts?.map((shopProduct) => {
            return (
              <div className={`col-6 col-md-${colSize} my-2 ps-md-0`} key={shopProduct?.id}>
                <ProductCard product={shopProduct} />
              </div>
            )
          })}
        </div>
      ) : (<div> {t("general.noResults")} </div>))
      : <div className="loading-screen">
        <Spinner />
        <h4 className="ms-2"> Loading... </h4>
      </div>
    }

    {!!shopProducts?.length && (
      <div className="d-flex justify-content-center align-items-center my-5 flex-column">
        <h6 className="text-uppercase">
          {t("shop.showing")} {shopProducts?.length} {t("shop.of")}{" "}
          {totalCount} {t("shop.items")}
        </h6>
        <div className="w-25 my-3">
          <div
            style={{ width: `${percentage}%` }}
            className="text-end small"
          >
            {percentage}%
          </div>
          <ProgressBar now={percentage} variant="dark" />
        </div>
        {shopProducts?.length !== totalCount && (
          <h6
            className="text-uppercase hover-underline-animation cursor-pointer fw-bold"
            onClick={fetchMoreData}
          >
            {t("shop.showMore")}
          </h6>
        )}
      </div>
    )}
  </>
}
