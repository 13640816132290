import { brandsData } from '../../../../utils/data/brandsData';
import ImageHandler from '../../../../shared/components/ImageHandler';
import { useTranslation } from 'react-i18next';

export default function PopularBrands() {
    const { t } = useTranslation();

    return (
        <div className="col-12 mt-3">
            <h2 className="fw-bold text-center" data-aos="slide-right"> {t("general.mostPopularBrands")} </h2>

            <div className="row align-items-center justify-content-center my-5" >
                {brandsData?.map(brand => {
                    return (
                        <div className="col-6 col-md-2" key={brand?.id}>
                            <ImageHandler src={brand?.path} className='w-100 object-fit-contain' />
                        </div>
                    )
                })}
            </div>
        </div >
    )
}
